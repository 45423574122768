export default class Diagnostic {
  enabled = false;

  constructor() {}

  getCookie(cname) {
    let name = cname + '=';
    const documentCookieEncoded = document.cookie
      .split(';')
      .map((cookie) => {
        if (cookie.includes('_gig_llu')) {
          return escape(cookie);
        }

        return cookie;
      })
      .join(';');

    const decodedCookie = decodeURIComponent(documentCookieEncoded);

    let ca = decodedCookie.split(';');

    for (let c of ca) {
      if (c.indexOf(name) !== -1) {
        return c.substring(c.indexOf(name) + name.length, c.length);
      }
    }

    return '';
  }

  log(toLog, ...args) {
    if (this.enabled) {
      const millis = Date.now() - window.ddnawrapper.diagnostic.startTime;

      let params = args ?? [];
      params = params.length > 0 ? params.flat() : '';

      console.debug(millis + ' ms. ' + 'DDNA WRAPPER - ' + toLog, ...params);
    }
  }

  init() {
    window.ddnawrapper = window.ddnawrapper || {};
    let diagnostic = window.ddnawrapper.diagnostic || { initialized: false };

    if (!diagnostic.initialized) {
      let debugCookie = this.getCookie('d3Debug');
      this.enabled = debugCookie.length > 0;
      diagnostic.enabled = this.enabled;
      diagnostic.initialized = true;
      diagnostic.startTime = Date.now();

      diagnostic.log = this.log;

      window.ddnawrapper = window.ddnawrapper || {};
      window.ddnawrapper.diagnostic = window.ddnawrapper.diagnostic || {};

      window.ddnawrapper.diagnostic = diagnostic;

      diagnostic.log('Diagnostic enabled by cookie');
    }
  }
}
