import { getCookie, getQueryStringValue, getUrlParams } from '../utils/utils.js';
import { cookieCategories } from '../config';

export default class OneTrustService {
  static externalConsent = undefined;

  constructor() {
    this.isLoaded = false;
    this.hasOptanonBoxClosedCookie = false;
    this.isOptOutMode = false;

    window.ddnawrapper.consent = window.ddnawrapper.consent || {};
    window.ddnawrapper.consent.fillD3ConsentVariablesFromOneTrustCookie =
      this.fillD3ConsentVariablesFromOneTrustCookie;

    window.ddnawrapper.events = window.ddnawrapper.events || {};
  }

  triggerCookieLoadedEvent() {
    window.ddnawrapper = window.ddnawrapper || {};
    window.ddnawrapper.events = window.ddnawrapper.events || {};
    if (!window.ddnawrapper.events.cookieLoadedTriggered) {
      const event = new CustomEvent('cookies-loaded');
      window.dispatchEvent(event);
      window.ddnawrapper.events.cookieLoadedTriggered = true;
    }
  }

  initFunctionsForThirdParties() {
    let diagnostic = window.ddnawrapper.diagnostic || {};
    const globalConfig = window.GlobalConfiguration || {};
    diagnostic.log('Init consent object');
    window.ddnawrapper = window.ddnawrapper || {};
    window.ddnawrapper.consent = window.ddnawrapper.consent || {};

    window.ddnawrapper.consent.isSpecialTerritory = () => {
      const specialTerritoriesList = globalConfig.segmentSpecialMarkets || '';
      let { userNation } = window.deltatre;

      if (typeof userNation === 'undefined') {
        // LOG to console on pourpose to give some message to third party using this function
        console.warn('User nation not yet ready');

        return null;
      }

      // Check if user nation should be overwritten by query parameter for testing purpose
      const userNationParam = getQueryStringValue('user_nation');
      if (userNationParam) {
        diagnostic.log(
          `User nation overwritten by query string parameter. Was: ${userNation} and has been set to ${userNationParam}`
        );
        if (typeof userNationParam === 'string' || userNationParam instanceof String) {
          userNation = userNationParam.toUpperCase();
        }
      }

      diagnostic.log(
        `Current user nation is: ${userNation}. Special territories is: ${specialTerritoriesList}`
      );

      return specialTerritoriesList.indexOf(userNation) !== -1;
    };

    const checkMarketingTrackingConsentGiven = () => {
      ddnawrapper.consent.fillD3ConsentVariablesFromOneTrustCookie();
      diagnostic.log('Checking if marketing/tracking consent has been given');

      const trackingConsentCategory =
        globalConfig.segmentCookieCategory || cookieCategories.targeting;

      return (
        ddnawrapper.consent?.consentCategories !== undefined &&
        ddnawrapper.consent?.consentCategories[trackingConsentCategory] &&
        ddnawrapper.consent?.consentCategories[trackingConsentCategory] === '1'
      );
    };

    window.ddnawrapper.consent.isTrackingConsentGiven = () => {
      return checkMarketingTrackingConsentGiven();
    };

    window.ddnawrapper.consent.isMarketingConsentGiven = () => {
      return checkMarketingTrackingConsentGiven();
    };

    window.ddnawrapper.consent.isSocialMediaConsentGiven = () => {
      ddnawrapper.consent.fillD3ConsentVariablesFromOneTrustCookie();
      diagnostic.log('Checking if social media consent has been given');
      const socialMediaConsentCategory =
        globalConfig.socialMediaCookieCategory || cookieCategories.socialMedia;

      return (
        ddnawrapper.consent?.consentCategories !== undefined &&
        ddnawrapper.consent?.consentCategories[socialMediaConsentCategory] &&
        ddnawrapper.consent?.consentCategories[socialMediaConsentCategory] === '1'
      );
    };

    window.ddnawrapper.consent.isPerformanceConsentGiven = () => {
      ddnawrapper.consent.fillD3ConsentVariablesFromOneTrustCookie();
      diagnostic.log('Checking if performances consent has been given');
      const performancesConsentCategory =
        globalConfig.performancesCookieCategory || cookieCategories.performance;

      return (
        ddnawrapper.consent?.consentCategories !== undefined &&
        ddnawrapper.consent?.consentCategories[performancesConsentCategory] &&
        ddnawrapper.consent?.consentCategories[performancesConsentCategory] === '1'
      );
    };

    window.ddnawrapper.consent.getConsentFromCookie = () => {
      const cookieValue = getCookie('OptanonConsent');
      if (cookieValue.length > 0) {
        const cookieValuesDict = ddnawrapper.consent.decodeOptanonConsentCookie(cookieValue);

        return cookieValuesDict.groups;
      }
    };

    window.ddnawrapper.consent.isConsentGiven = () => {
      if (window.ddnawrapper.events?.cookieLoadedTriggered) {
        return window.ddnawrapper.events.cookieLoadedTriggered;
      } else {
        return false;
      }
    };
  }

  init(externalConsent) {
    window.ddnawrapper = window.ddnawrapper || {};
    window.ddnawrapper.consent = window.ddnawrapper.consent || {};

    if (externalConsent) {
      OneTrustService.externalConsent = externalConsent;
    }

    window.ddnawrapper.consent.decodeOptanonConsentCookie = function (cookieValue) {
      const cookieValuesDict = {};

      cookieValue.split('&').forEach((element) => {
        const elementKeyValue = element.split('=');

        if (elementKeyValue.length === 2) {
          cookieValuesDict[elementKeyValue[0]] = elementKeyValue[1];
        }
      });

      return cookieValuesDict;
    };

    this.initFunctionsForThirdParties();

    if (!window.ddnawrapper.consent.fillD3ConsentVariablesFromOneTrustCookie(externalConsent)) {
      return;
    }

    if (externalConsent) {
      this.hasOptanonBoxClosedCookie = true;
    } else {
      this.hasOptanonBoxClosedCookie = getCookie('OptanonAlertBoxClosed').length > 0;
    }

    this.isOptOutMode =
      ddnawrapper.consent?.consentCategories && ddnawrapper.consent.consentCategories.length > 1;

    if (this.hasOptanonBoxClosedCookie || this.isOptOutMode) {
      this.triggerCookieLoadedEvent();
    }
  }

  fillD3ConsentVariablesFromOneTrustCookie() {
    let cookieValue = getCookie('OptanonConsent');

    if (OneTrustService.externalConsent) {
      ddnawrapper.consent.consentCategories = OneTrustService.externalConsent;
      return true;
    }

    if (cookieValue.length > 0) {
      const cookieValuesDict = ddnawrapper.consent.decodeOptanonConsentCookie(cookieValue);
      const optanonCategoriesValues = cookieValuesDict.groups;

      if (optanonCategoriesValues) {
        ddnawrapper.consent.consentCategories = ddnawrapper.consent?.consentCategories || {};
        optanonCategoriesValues.split(',').forEach((category) => {
          const consentKeyValue = category.split(':');
          if (consentKeyValue.length === 2) {
            ddnawrapper.consent.consentCategories[consentKeyValue[0]] = consentKeyValue[1];
          }
        });
      }

      return true;
    }

    return false;
  }
}
