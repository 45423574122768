export const UserNationPath = '/en/api/js/usernation';
export const DdnaConfigurationUrl = '/api/js/ddna-configuration';
export const SegmentScriptUrl = 'https://cdn.segment.com/analytics.js/v1/';
export const ConsentScriptUrl = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

export const cookieCategoriesValues = ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'];

export const cookieCategories = {
  mandatory: 'C0001',
  performance: 'C0002',
  functionality: 'C0003',
  targeting: 'C0004',
  socialMedia: 'C0005',
};
