import { init, initEmbeddedWidget, initWebview } from '../libraries/libraries.js';
import { getUrlParams, detectAppleMobileDevices, isDisplayAsWebView } from '../utils/utils.js';

export default class Configuration {
  getSegmentAnonymousIdQueryParam = () => {
    let params = getUrlParams();

    return params['segment_anonymousid'] || params['AnonymousID'];
  };

  async init() {
    let globalConfiguration = window.GlobalConfiguration || {};

    if (
      window.DdnaOverrideConfiguration !== undefined &&
      window.DdnaOverrideConfiguration.isEmbeddedWidget !== undefined
    ) {
      globalConfiguration.isEmbeddedWidget = window.DdnaOverrideConfiguration?.isEmbeddedWidget;
    }

    if (
      isDisplayAsWebView() ||
      globalConfiguration.isDisplayedAsWebView ||
      globalConfiguration.isAppView
    ) {
      const anonId = this.getSegmentAnonymousIdQueryParam();

      if (
        anonId &&
        globalConfiguration.segmentKeyAppsIos &&
        globalConfiguration.segmentKeyAppsAndroid
      ) {
        let segmentWriteKey = detectAppleMobileDevices()
          ? globalConfiguration.segmentKeyAppsIos
          : globalConfiguration.segmentKeyAppsAndroid;

        window.ddnawrapper.diagnostic.log('Initialize segment as web view');
        initWebview(segmentWriteKey, anonId);
      }
    } else if (globalConfiguration.isEmbeddedWidget) {
      const segmentKey = globalConfiguration.segmentKeyEmbeds || '';
      const segmentSpecialMarkets = globalConfiguration.segmentSpecialMarkets || '';
      window.ddnawrapper.diagnostic.log('Initialize segment as embedded widget');

      if (this.invokeSpecialMarketExternalManagement()) {
        window.ddnawrapper.diagnostic.log('Special markets disabled by external party!');
        segmentSpecialMarkets = '';
      }

      initEmbeddedWidget(segmentKey, segmentSpecialMarkets);
    } else {
      const segmentKey = globalConfiguration.segmentKey || '';
      const segmentSpecialMarkets = globalConfiguration.segmentSpecialMarkets || '';

      window.ddnawrapper.diagnostic.log('Initialize segment');

      if (this.invokeSpecialMarketExternalManagement()) {
        window.ddnawrapper.diagnostic.log('Special markets disabled by external party!');
        segmentSpecialMarkets = '';
      }

      init(segmentKey, segmentSpecialMarkets);
    }
  }

  setSpecialMarketsExternal(event) {
    window.ddnawrapper.diagnostic.log(
      'Received special markets external configuration: ',
      event?.detail
    );

    window.GlobalConfiguration = window.GlobalConfiguration || {};
    window.GlobalConfiguration.specialMarketsDisabled =
      event?.detail?.specialMarketsDisabled ?? false;
  }

  invokeSpecialMarketExternalManagement = () => {
    window.addEventListener('analytics-configuration', this.setSpecialMarketsExternal, true);

    window.dispatchEvent(new CustomEvent('analytics-configuration-ready'));
    window.ddnawrapper.diagnostic.log(
      'Sent analytics-configuration-ready for external special market management'
    );

    // Wait for timeout before continue initialize special markets
    setTimeout(() => {
      window.ddnawrapper.diagnostic.log('Waiting for analytics-configuration event to be passed');
      window.removeEventListener('analytics-configuration', this.setSpecialMarketsExternal, true);
    }, parseInt(window.GlobalConfiguration?.specialMarketsConfigurationTimeout) ?? 500);

    return window.GlobalConfiguration?.specialMarketsDisabled ?? false;
  };
}
