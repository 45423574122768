export function isConsentGivenForCategory(category) {
  return (
    ddnawrapper.consent?.consentCategories !== undefined &&
    ddnawrapper.consent?.consentCategories[category] &&
    ddnawrapper.consent?.consentCategories[category] === '1'
  );
}

export function isConsentGiven(isSpecialMarket) {
  const purposesConfiguration = isSpecialMarket
    ? window.GlobalConfiguration.segmentGranularConsentConfiguration.specialTerritories
        ?.purposesCategories
    : window.GlobalConfiguration.segmentGranularConsentConfiguration.nonSpecialTerritories
        ?.purposesCategories;

  // With granular consent, this is true if at least 1 purpose has consent
  return (
    isConsentGivenForCategory(purposesConfiguration?.analytics?.category) ||
    isConsentGivenForCategory(purposesConfiguration?.marketing?.category) ||
    isConsentGivenForCategory(purposesConfiguration?.profiling?.category) ||
    isConsentGivenForCategory(purposesConfiguration?.retargeting?.category)
  );
}

export function getSpecialTerritoriesPurposesFromCategories() {
  const specTerritoriesPurposes =
    window.GlobalConfiguration.segmentGranularConsentConfiguration.specialTerritories
      .purposesCategories;

  return {
    analytics: isConsentGivenForCategory(specTerritoriesPurposes.analytics.category),
    retargeting: isConsentGivenForCategory(specTerritoriesPurposes.retargeting.category),
    profiling: isConsentGivenForCategory(specTerritoriesPurposes.profiling.category),
    marketing: isConsentGivenForCategory(specTerritoriesPurposes.marketing.category),
  };
}

export function getSpecialTerritoriesPurposesFromCategoriesOnRejectedConsent() {
  const specTerritoriesPurposes =
    window.GlobalConfiguration.segmentGranularConsentConfiguration.specialTerritories
      .purposesCategories;

  return {
    analytics: specTerritoriesPurposes.analytics.valueOnPendingConsent,
    retargeting: specTerritoriesPurposes.retargeting.valueOnPendingConsent,
    profiling: specTerritoriesPurposes.profiling.valueOnPendingConsent,
    marketing: specTerritoriesPurposes.marketing.valueOnPendingConsent,
  };
}

export function getNonSpecialTerritoriesPurposesFromCategories() {
  const nonSpecialTerritoriesPurposes =
    window.GlobalConfiguration.segmentGranularConsentConfiguration.nonSpecialTerritories
      .purposesCategories;

  return {
    analytics: isConsentGivenForCategory(nonSpecialTerritoriesPurposes.analytics.category),
    retargeting: isConsentGivenForCategory(nonSpecialTerritoriesPurposes.retargeting.category),
    profiling: isConsentGivenForCategory(nonSpecialTerritoriesPurposes.profiling.category),
    marketing: isConsentGivenForCategory(nonSpecialTerritoriesPurposes.marketing.category),
  };
}
