import { UserNationPath } from '../config.js';
import { getHost } from '../utils/utils.js';
export default class UserNation {
  constructor() {}

  async init() {
    return new Promise((resolve, reject) => {
      try {
        const userNation = window.deltatre?.userNation;

        if (userNation) {
          return resolve();
        }

        const host = getHost();
        const url = new URL(`https://${host}/`);
        url.pathname = UserNationPath;

        console.log('user-nation not provided, try to read from ' + url.toString());

        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', url.toString());

        document.head.appendChild(script);

        script.addEventListener('load', () => {
          return resolve();
        });
      } catch (err) {
        return reject(err);
      }
    });
  }
}
