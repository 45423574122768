import { SegmentScriptUrl } from '../config';

function loadSegment(onReadyCallback) {
  if (!window.setupAnalytics) {
    window.setupAnalytics = true;

    // Create a queue, but don't obliterate an existing one!
    let analytics = (window.analytics = window.analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }

      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    analytics.methods = ['setAnonymousId', 'identify', 'track', 'ready', 'page'];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function () {
        let args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (let method of analytics.methods) {
      analytics[method] = analytics.factory(method);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (evtKey, evtOptions) {
      let diagnostic = window.ddnawrapper.diagnostic || {};

      diagnostic.log(
        'SEGMENT - library load' +
          (evtOptions === undefined ? '' : ' - options: ' + JSON.stringify(evtOptions.integrations))
      );
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = false;

      //fires once analytics library is loaded and ready. Analytics calls prior to this event are queued and replayed.
      analytics.ready(() => onReadyCallback('segment'));

      script.src = SegmentScriptUrl + evtKey + '/analytics.min.js';

      analytics._loadOptions = evtOptions;

      let first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0';
  }
}

export { loadSegment };
