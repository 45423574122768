/**
 * Observe an element and if an entry is intersecting,
 * will unobserve it and send an event on the window
 * @param {DOMElement} element DOM element to observe
 * @param {object} options Options for the intersection observer class
 * @param {string} eventName Event name to trigger when entry is intersecting
 */
export function observeElement(element, options, eventName) {
  const callback = (entry, observer) => {
    if (entry.isIntersecting) {
      dispatchEvent(new CustomEvent(eventName, { detail: { data: entry.target } }));

      observer.unobserve(entry.target);
    }
  };

  observeElementWithCallback(element, options, callback);
}

/**
 * @callback observerCallback ff
 * @param {DOMElement} entry Entry from the intersection
 * @param {IntersectionObserver} observer Observer instance
 * @return {void}
 */

/**
 *
 * @param {DOMElement} element DOM element to observe
 * @param {object} options Options for the intersection observer class
 * @param {observerCallback} callback Function called with the entry and observer parameter
 * @return {void}
 */
export function observeElementWithCallback(element, options, callback) {
  const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
      callback(entry, obs);
    });
  }, options);

  observer.observe(element);
}
