import OneTrustService from './oneTrustService.js';
import { observeElementWithCallback } from '../utils/intersect-observer.js';

export default class D3OneTrustService extends OneTrustService {
  async init() {
    window.ddnawrapper = window.ddnawrapper || {};
    let diagnostic = window.ddnawrapper.diagnostic || { initialized: false };

    super.init();

    //bind cookies-loaded handlers
    window.addEventListener('cookies-loaded', function () {
      diagnostic.log('triggered cookies-loaded');
      let isEnabledOneTrustPerfomance =
        !!window.OptanonActiveGroups && ddnawrapper.consent.isPerformanceConsentGiven();
      window['ga-disable-@googleAnalyticsId'] = !isEnabledOneTrustPerfomance;
    });

    let otherOptanonWrapper;
    if (typeof window.OptanonWrapper === 'function') {
      otherOptanonWrapper = window.OptanonWrapper;
    }

    window.OptanonWrapper = function () {
      diagnostic.log('OptanonWrapper - Entered');

      window.dataLayer.push({ event: 'OneTrustGroupsUpdated' });

      let consentModelName = 'opt-in'; // EU, show banner
      try {
        consentModelName = Optanon.GetDomainData().ConsentModel.Name;
      } catch (e) {
        // continue regardless of error
      }

      if (Optanon.IsAlertBoxClosedAndValid() || consentModelName === 'opt-out') {
        diagnostic.log('OptanonWrapper - IsAlertBoxClosedAndValid');
        let prevCookieConsent = JSON.stringify(ddnawrapper.consent.consentCategories);
        ddnawrapper?.consent?.fillD3ConsentVariablesFromOneTrustCookie();
        let currCookieConsent = JSON.stringify(ddnawrapper.consent.consentCategories);

        // if opt out, all consent should be set by default if the cookie is not present
        if (consentModelName === 'opt-out' && !ddnawrapper.consent.consentCategories) {
          ddnawrapper.consent.consentCategories = {
            C0001: '1',
            C0002: '1',
            C0003: '1',
            C0004: '1',
            C0005: '1',
          };
        }

        if (!window.ddnawrapper.events.cookieLoadedTriggered) {
          diagnostic.log('OptanonWrapper - triggering cookie loaded');
          const event = new CustomEvent('cookies-loaded');
          window.dispatchEvent(event);
          window.ddnawrapper.events.cookieLoadedTriggered = true;
        } else {
          if (prevCookieConsent === currCookieConsent) {
            diagnostic.log('OptanonWrapper - cookie consent unchanged');
          } else {
            diagnostic.log('OptanonWrapper - triggering cookie consent updated');
            const cookieConsentUpdatedEvent = new CustomEvent('cookies-consent-updated');
            window.dispatchEvent(cookieConsentUpdatedEvent);
          }
        }
      } else {
        if (!window.ddnawrapper.events.cookieBannerShownTriggered) {
          window.ddnawrapper.diagnostic.log(
            'Opt in mode, consent not yet ready -> triggering cookies banner shown event'
          );
          const cookiesBannerShownEvent = new CustomEvent('cookies-banner-shown');
          window.dispatchEvent(cookiesBannerShownEvent);
          window.ddnawrapper.events.cookieBannerShownTriggered = true;
        } else {
          window.ddnawrapper.diagnostic.log(
            'Opt in mode, consent not yet ready and cookies banner shown event already triggered'
          );
        }
      }
      if (typeof otherOptanonWrapper === 'function') {
        otherOptanonWrapper();
      }

      const rejectButtons = document.getElementsByClassName('ot-pc-refuse-all-handler');
      if (rejectButtons.length > 0) {
        rejectButtons[0].setAttribute('data-cy', 'onetrust-reject-button');
      }

      const confirmChoiceButtons = document.getElementsByClassName(
        'save-preference-btn-handler onetrust-close-btn-handler'
      );
      if (confirmChoiceButtons.length > 0) {
        confirmChoiceButtons[0].setAttribute('data-cy', 'onetrust-confirm-choice-button');
      }

      const setButtonOrder = () => {
        let retries = 0;

        const oneConsentSdk = document.getElementById('onetrust-consent-sdk');

        if (oneConsentSdk) {
          const acceptBtn = document.getElementById('onetrust-accept-btn-handler');
          const declineBtn = document.getElementById('onetrust-reject-all-handler');
          const cookieSettingsBtn = document.getElementById('onetrust-pc-btn-handler');
          const btnContainer = document.getElementById('onetrust-button-group');

          if (btnContainer) {
            btnContainer.style.display = 'flex';
            btnContainer.style.alignItems = 'center';
            btnContainer.style.justifyContent = 'center';

            if (acceptBtn) {
              acceptBtn.style.order = 1;
              acceptBtn.style.height = '70px';
            }

            if (declineBtn) {
              declineBtn.style.order = 2;
              declineBtn.style.height = '70px';
            }

            if (cookieSettingsBtn) {
              cookieSettingsBtn.style.order = 3;
              cookieSettingsBtn.style.height = '70px';
            }

            const oneTrustSdkOverlay = document.querySelector('.onetrust-pc-dark-filter');

            if (oneTrustSdkOverlay) {
              oneTrustSdkOverlay.style.width = '100%';
              oneTrustSdkOverlay.style.height = '100%';
            }

            const oneTrustSdkContainer = document.querySelector('#onetrust-banner-sdk');

            if (oneTrustSdkContainer) {
              oneTrustSdkContainer.style.height = 'auto';
            }

            oneConsentSdk.style.display = 'block';
          }
        } else {
          if (retries < 5) {
            retries++;
            window.setTimeout(setButtonOrder, 500);
          }
        }

        // Remove 'loadCookiesBannerHideStylesScript' style tag from ddna-wrapper.js
        document.querySelector('#one-trust-hide-consent')?.remove();
      };

      window.setTimeout(setButtonOrder, 500);
    };
  }

  externalConsentGiven(externalConsent) {
    let prevCookieConsent = JSON.stringify(ddnawrapper.consent.consentCategories);

    super.init(externalConsent);

    window.ddnawrapper.diagnostic.log('Consent - received external consent. Populating categories');

    window.ddnawrapper?.consent?.fillD3ConsentVariablesFromOneTrustCookie();
    let currCookieConsent = JSON.stringify(ddnawrapper.consent.consentCategories);

    if (!window.ddnawrapper.events.cookieLoadedTriggered) {
      window.ddnawrapper.diagnostic.log('Consent - triggering cookie loaded');

      const event = new CustomEvent('cookies-loaded');
      window.dispatchEvent(event);

      window.ddnawrapper.events.cookieLoadedTriggered = true;
    } else {
      if (prevCookieConsent === currCookieConsent) {
        window.ddnawrapper.diagnostic.log('Consent - cookie consent unchanged');
      } else {
        window.ddnawrapper.diagnostic.log('Consent - triggering cookie consent updated');

        const cookieConsentUpdatedEvent = new CustomEvent('cookies-consent-updated');
        window.dispatchEvent(cookieConsentUpdatedEvent);
      }
    }
  }

  initFunctionsForThirdParties() {
    super.initFunctionsForThirdParties();
  }
}
