import { deleteCookie, getCookie, getQueryStringValue } from '../utils/utils';
import { isConsentGivenForCategory } from './consent';

export function isSpecialMarketUser(specialTerritoriesList) {
  window.ddnawrapper.diagnostic.log('check if user is in a special market');

  let userNation = window.deltatre.userNation;

  // Check if user nation should be overwritten by query parameter for testing purpose
  let userNationParam = getQueryStringValue('user_nation');
  if (userNationParam) {
    window.ddnawrapper.diagnostic.log(
      'User nation overwritten by query string parameter. Was: ' +
        userNation +
        ' and has been set to ' +
        userNationParam
    );

    if (typeof userNationParam === 'string' || userNationParam instanceof String) {
      userNation = userNationParam.toUpperCase();
    }
  }

  window.ddnawrapper.diagnostic.log(
    'Current user nation is: ' + userNation + '. Special territories is: ' + specialTerritoriesList
  );

  return specialTerritoriesList.indexOf(userNation) !== -1;
}

export function buildIntegrationsObject(isSpecialMarket) {
  let destinationPreferences = isSpecialMarket
    ? window.GlobalConfiguration.segmentGranularConsentConfiguration.specialTerritories
        .destinationPreferences
    : window.GlobalConfiguration.segmentGranularConsentConfiguration.nonSpecialTerritories
        .destinationPreferences;

  let integrations = {};

  for (const destination in destinationPreferences) {
    integrations[destination] = isConsentGivenForCategory(destinationPreferences[destination]);
  }

  return integrations;
}

// Cookie override https://ocsproduct.atlassian.net/browse/B2P-9553
export function handleSpecialTerritoryOverride() {
  const segmentCookieValue = getCookie('ajs_anonymous_id');
  const ddnaAnalyticsCookieValue = getCookie('dl_anonymous_id');

  if (
    segmentCookieValue &&
    ddnaAnalyticsCookieValue &&
    ddnaAnalyticsCookieValue !== 'anonymous_user' &&
    segmentCookieValue === 'anonymous_user'
  ) {
    window.ddnawrapper.diagnostic.log('special market - cookie override delete');
    deleteCookie('dl_anonymous_id', '/', '.olympics.com');
    deleteCookie('dl_anonymous_id', '/', '.stg-www.olympic-channelservices.org');
  }
}
