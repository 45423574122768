import { getUrlParams } from '../utils/utils.js';
import { observeElementWithCallback } from '../utils/intersect-observer.js';
import { loadSegment } from './segment.js';
import { loadParadigma } from './paradigma.js';
import {
  getNonSpecialTerritoriesPurposesFromCategories,
  getSpecialTerritoriesPurposesFromCategories,
  getSpecialTerritoriesPurposesFromCategoriesOnRejectedConsent,
  isConsentGiven,
  isConsentGivenForCategory,
} from './consent.js';
import { handleSpecialTerritoryOverride, isSpecialMarketUser } from './special-markets.js';
import { shouldLoadParadigma, shouldLoadSegment } from '../utils/configuration-helper.js';

let isSpecialMarket = false;
let segmentLoaded = false;
let paradigmaLoaded = false;
let isFullyLoaded = false;
let anonymousUser = 'anonymous_user';
let trackTimedAction = false;

let countSeconds = 30;
let countMilliSeconds = 30000;

function initWebview(segmentKey, anonymousId) {
  if (window.GlobalConfiguration && window.GlobalConfiguration.disableAnalytics) {
    return;
  }

  const setupInWebview = () => {
    if (!isConsentGiven()) {
      return;
    }

    setupAnalytics();
    window.AnalyticsWrapper.setAnonymousId(anonymousId);

    if (!window.segmentInit) {
      window.AnalyticsWrapper.load(segmentKey);
      window.segmentInit = true;
    }
  };

  if (window.cookieLoadedTriggered) {
    setupInWebview();
  } else {
    window.ddnawrapper.diagnostic.log("awaiting 'cookies-loaded' event");
    window.addEventListener('cookies-loaded', function () {
      //comes from 'cookies-loaded' event which is fired once 'OptanonAlertBoxClosed' is validated. only fired once
      window.ddnawrapper.diagnostic.log('received cookies loaded event');
      setupInWebview();
    });
  }
}

function initEmbeddedWidget(segmentKey, segmentSpecialMarkets) {
  if (window.GlobalConfiguration && window.GlobalConfiguration.disableAnalytics) {
    return;
  }

  const params = getUrlParams();

  let marketingConsentGiven = params['marketingConsent'] && params['marketingConsent'] === 'true';
  let performanceConsentGiven =
    params['performanceConsent'] && params['performanceConsent'] === 'true';
  const oldConsentGiven = params['consent_given'] && params['consent_given'] === 'true';

  window.ddnawrapper.diagnostic.log(
    `Embedded Widget - set cookie consent variable.
    Performance consent: ${performanceConsentGiven}, marketing consent: ${marketingConsentGiven}`
  );

  if (
    marketingConsentGiven === undefined &&
    performanceConsentGiven === undefined &&
    oldConsentGiven !== undefined
  ) {
    // Old consent variable
    window.ddnawrapper.diagnostic.log(
      `Embedded Widget - set cookie consent variable.
      Using old consent parameter as a fallback: ${oldConsentGiven}`
    );

    marketingConsentGiven = oldConsentGiven;
    performanceConsentGiven = oldConsentGiven;
  } else {
    // set default to false
    if (marketingConsentGiven === undefined) {
      marketingConsentGiven = false;
    }

    if (performanceConsentGiven === undefined) {
      performanceConsentGiven = false;
    }
  }

  window.ddnawrapper = window.ddnawrapper || {};
  window.ddnawrapper.consent = window.ddnawrapper.consent || {};

  window.ddnawrapper.consent.consentCategories = {
    C0001: '1',
    C0002: performanceConsentGiven ? '1' : '0',
    C0003: '0',
    C0004: marketingConsentGiven ? '1' : '0',
    C0005: '0',
  };

  handleEmbeddedPageView(segmentKey, segmentSpecialMarkets);
}

function init(segmentKey, segmentSpecialMarkets) {
  if (window.GlobalConfiguration && window.GlobalConfiguration.disableAnalytics) {
    return;
  }

  setupAnalytics(); //add analytics to the global namespace as soon as possible

  isSpecialMarket = isSpecialMarketUser(segmentSpecialMarkets);

  if (window.cookieLoadedTriggered) {
    //comes from 'cookies-loaded' event which is fired once 'OptanonAlertBoxClosed' is validated. only fired once
    window.ddnawrapper.diagnostic.log('onetrust cookie is present');
    runSetup(segmentKey);
  } else {
    if (isSpecialMarket) {
      window.ddnawrapper.diagnostic.log('special market - onetrust cookie not present');
      specTerritoriesInit(segmentKey, specTerritoriesIdentifyDefaultUser);
      addCookieConsentChangeListener();
    }

    window.ddnawrapper.diagnostic.log("awaiting 'cookies-loaded' event");
    window.addEventListener('cookies-loaded', function () {
      //comes from 'cookies-loaded' event which is fired once 'OptanonAlertBoxClosed' is validated. only fired once
      window.ddnawrapper.diagnostic.log('received cookies loaded event');
      runSetup(segmentKey);
    });
  }

  window.addEventListener('cookies-consent-updated', function () {
    window.ddnawrapper.diagnostic.log(
      "received 'cookies-consent-updated' event => init segment again if needed"
    );
    resetAnonymousId();
  });
}

function addCookieConsentChangeListener() {
  // Adding listener to check if consent changes - fired from optanonwrapper after any consent cookie changes
  window.ddnawrapper.diagnostic.log("awaiting 'cookies-consent-updated' event");
  window.addEventListener('cookies-consent-updated', function () {
    window.ddnawrapper.diagnostic.log('received cookies consent updated');
    if (isSpecialMarket) {
      specTerritoriesTriggerIdentify();
    }
  });
}

function runSetup(segmentKey) {
  let trackingConsent = isConsentGiven();

  if (trackingConsent && !segmentLoaded) {
    window.ddnawrapper.diagnostic.log('tracking consent given - proceed with Segment setup');
    handleConsentGiven(segmentKey);
  } else {
    if (isSpecialMarket) {
      if (!window.segmentInit) {
        specTerritoriesInit(segmentKey, specTerritoriesIdentifyAnonymousUser);
      } else {
        specTerritoriesTriggerIdentify();
      }
    } else {
      window.ddnawrapper.diagnostic.log('tracking consent not given - segment not loaded');
    }

    window.segmentInitializationCompleted = true;
  }
}

function buildIntegrationsObject() {
  let destinationPreferences = isSpecialMarket
    ? getConfigurationOrFallback().specialTerritories.destinationPreferences
    : getConfigurationOrFallback().nonSpecialTerritories.destinationPreferences;

  let integrations = {};

  for (const destination in destinationPreferences) {
    integrations[destination] = isConsentGivenForCategory(destinationPreferences[destination]);
  }

  return integrations;
}

function handleConsentGiven(apiKey) {
  if (!window.segmentInit) {
    const destinationPreferences = buildIntegrationsObject();
    window.AnalyticsWrapper.load(apiKey, { integrations: destinationPreferences });
    window.segmentInit = true;

    if (isSpecialMarket) {
      specTerritoriesIdentifyConsentingUser();
    } else {
      identifyConsentingUser();
    }
  }
}

function specTerritoriesInit(apiKey, identifyCallback) {
  window.ddnawrapper.diagnostic.log('special market - proceed with Segment setup');

  handleSpecialTerritoryOverride();

  if (!window.segmentInit) {
    const destinationPreferences = buildIntegrationsObject();
    window.AnalyticsWrapper.load(apiKey, { integrations: destinationPreferences });
    identifyCallback();
    window.segmentInit = true;
  }
}

function specTerritoriesTriggerIdentify() {
  if (isConsentGiven()) {
    specTerritoriesIdentifyConsentingUser();
  } else {
    specTerritoriesIdentifyAnonymousUser();
  }
}

function specTerritoriesIdentifyDefaultUser() {
  window.ddnawrapper.diagnostic.log('special market - fire identify (pending)');
  const specTerritoriesPurposes =
    getConfigurationOrFallback().specialTerritories.purposesCategories;

  window.ddnawrapper.analytics.identify({
    gdpr_privacy: 'pending',
    is_special_market: true,
    purposes: {
      analytics: specTerritoriesPurposes.analytics.valueOnPendingConsent,
      retargeting: specTerritoriesPurposes.retargeting.valueOnPendingConsent,
      profiling: specTerritoriesPurposes.profiling.valueOnPendingConsent,
      marketing: specTerritoriesPurposes.marketing.valueOnPendingConsent,
    },
  });
}

function specTerritoriesIdentifyConsentingUser() {
  window.ddnawrapper.diagnostic.log(
    'special market - tracking consent given - fire identify (accepted)'
  );

  window.ddnawrapper.analytics.identify({
    gdpr_privacy: 'accepted',
    is_special_market: true,
    purposes: getSpecialTerritoriesPurposesFromCategories(),
  });
}

function specTerritoriesIdentifyAnonymousUser() {
  window.ddnawrapper.diagnostic.log(
    'special market - tracking consent not given - set generic anonymous id - fire identify (rejected)'
  );

  window.AnalyticsWrapper.setAnonymousId(anonymousUser);
  window.ddnawrapper.analytics.identify({
    gdpr_privacy: 'rejected',
    is_special_market: true,
    purposes: getSpecialTerritoriesPurposesFromCategoriesOnRejectedConsent(),
  });
}

function identifyConsentingUser() {
  window.ddnawrapper.diagnostic.log(
    'regular territory - tracking consent given - fire identify (accepted)'
  );

  window.ddnawrapper.analytics.identify({
    gdpr_privacy: 'accepted',
    is_special_market: false,
    purposes: getNonSpecialTerritoriesPurposesFromCategories(),
  });
}

function resetAnonymousId() {
  if (isConsentGiven()) {
    if (!window.AnalyticsWrapper.userIsReady()) {
      window.AnalyticsWrapper.ready(doReset, true);
    } else {
      doReset();
    }
  }

  function doReset() {
    if (window.AnalyticsWrapper.user().anonymousId() === anonymousUser) {
      window.ddnawrapper.diagnostic.log('resetting anonymousId');
      window.AnalyticsWrapper.reset();
      setAnonymousIdOnDatalayer();
    }
  }
}

function setAnonymousIdOnDatalayer() {
  // If we are in embedded mode (AMP pages) we don't have dataLayer on window object
  if (!window.dataLayer) return;

  const id = window.AnalyticsWrapper.user()?.anonymousId();
  window.dataLayer.push({
    anonymousId: id,
  });
}

function setupAnalytics() {
  const isSegment = shouldLoadSegment();
  const isParadigma = shouldLoadParadigma();

  const onReadyCallback = (library) => {
    // Wait for both libraries if needed before sending analytics-ready
    if (library === 'segment') {
      segmentLoaded = true;
    }

    if (library === 'paradigma') {
      paradigmaLoaded = true;
    }

    if (isSegment && isParadigma) {
      isFullyLoaded = segmentLoaded && paradigmaLoaded;
    } else if (isSegment) {
      isFullyLoaded = segmentLoaded;
    } else if (isParadigma) {
      isFullyLoaded = paradigmaLoaded;
    }

    if (isFullyLoaded) {
      setAnonymousIdOnDatalayer();
      window.ddnawrapper = window.ddnawrapper || {};
      window.ddnawrapper.events = window.ddnawrapper.events || {};
      if (!window.ddnawrapper.events.analyticsReady) {
        const analyticsReady = new CustomEvent('analytics-ready');
        window.dispatchEvent(analyticsReady);
        window.ddnawrapper.events.analyticsReady = true;
      }
    }
  };

  if (isSegment) {
    loadSegment(onReadyCallback);
  }

  if (isParadigma) {
    loadParadigma(onReadyCallback);
  }
}

function getConfigurationOrFallback() {
  return window.GlobalConfiguration.segmentGranularConsentConfiguration;
}

function startTimedTracking() {
  if (!trackTimedAction) {
    trackTimedAction = setInterval(
      async function () {
        const props = {
          seconds: countSeconds,
          nonInteraction: 1,
        };

        await window.ddnawrapper.analytics.track('Pageview Heartbeat', props);

        countSeconds = countSeconds + countMilliSeconds / 1000;
      }.bind(this),
      countMilliSeconds
    );
  }
}

function stopTimedTracking() {
  clearInterval(this.trackTimedAction);
  trackTimedAction = false;
}

function handleEmbeddedPageView(segmentKey, segmentSpecialMarkets) {
  const options = {
    rootMargin: '0px',
    threshold: 1,
  };

  window.ddnawrapper.diagnostic.log(
    'embedded widget => check for intersection with parent before setup'
  );

  let heartbeatStarted = false;
  const pageViewHearthbeatCallback = (entry, _observer) => {
    if (entry.isIntersecting && !heartbeatStarted) {
      window.ddnawrapper.diagnostic.log('embedded widget => start timed tracking');
      startTimedTracking();
      heartbeatStarted = true;
    } else if (heartbeatStarted) {
      window.ddnawrapper.diagnostic.log('embedded widget => stop timed tracking');
      stopTimedTracking();
      heartbeatStarted = false;
    }
  };

  const pageViewCallback = (entry, observer) => {
    if (entry.isIntersecting) {
      window.ddnawrapper.diagnostic.log('embedded widget => intersecting, run segment');

      setupAnalytics();
      isSpecialMarket = isSpecialMarketUser(segmentSpecialMarkets);
      runSetup(segmentKey);

      observer.unobserve(entry.target);

      observeElementWithCallback(document.documentElement, options, pageViewHearthbeatCallback);
    }
  };

  observeElementWithCallback(document.documentElement, options, pageViewCallback);
}

export { init, initWebview, initEmbeddedWidget };
