export function shouldLoadParadigma() {
  return window.GlobalConfiguration.externalLibrary.includes('paradigma');
}

export function shouldLoadSegment() {
  return window.GlobalConfiguration.externalLibrary.includes('segment');
}

export function shouldUseMobileAppBridge() {
  return window.OCSMobileAppBridge && window.GlobalConfiguration.activateNewWebViewLogic === 'true';
}
