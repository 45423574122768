export const fn = function (value) {
  const str = toString.call(value);
  return (
    str === '[object Function]' ||
    str === '[object GeneratorFunction]' ||
    str === '[object AsyncFunction]'
  );
};

export const object = function (value) {
  return toString.call(value) === '[object Object]';
};

export const string = function (value) {
  return toString.call(value) === '[object String]';
};
